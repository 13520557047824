import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=e85663c6&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=e85663c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e85663c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBadge,VCard,VCardText,VCol,VContainer,VLazy,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSelect})
